import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';

import { ApiUrl } from '../shared/constants/nome-url';
import { environment } from './../../environments/environment';
import { HttpErroTratamentoUtil } from '../shared/classes/http-erro-tratamento-util';

@Injectable()
export class LoginService {
  private usuarioAutenticado = false;

  constructor(
    private httpClient: HttpClient,
    private httpErroTratamentoUtil: HttpErroTratamentoUtil
  ) { }

  fazerLogin(usuario) {
    return this.httpClient.post(environment.API_ENDPOINT + ApiUrl.LOGIN, usuario)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(error => {
          this.httpErroTratamentoUtil.tratamento(error.error);
          return Promise.reject(error.error);
        })
      )
      .toPromise()
  }

  logout(idUsuario) {
    // remove user from local storage to log user out
    localStorage.removeItem('token');

    return this.httpClient.get(environment.API_ENDPOINT + ApiUrl.LOGOUT + '/' + idUsuario)
      .pipe(
        map(res => res)
      );
  }

  usuarioEstaAutenticado() {
    const token = localStorage.getItem('token');
    if (token) {
      this.usuarioAutenticado = true;
    } else {
      this.usuarioAutenticado = false;
    }
    return this.usuarioAutenticado;
  }

  autenticarUsuario(autenticar: boolean) {
    this.usuarioAutenticado = autenticar
  }
}
