import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, map, catchError } from 'rxjs/operators';

import { environment } from './../../../environments/environment';
import { ApiUrl } from '../../shared/constants/nome-url';
import { HeaderService } from '../../shared/services/header.service';
import { HttpErroTratamentoUtil } from '../classes/http-erro-tratamento-util';

@Injectable()
export class ReEnviaConviteService {
  constructor(
    private httpClient: HttpClient,
    private header: HeaderService,
    private httpErroTratamentoUtil: HttpErroTratamentoUtil
  ) { }

  reEnviaConvite(idUsuario) {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.post(environment.API_ENDPOINT + ApiUrl.GERENCIAR_USUARIO_CONVITE, { id_usuario: idUsuario }, options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }
}
