import { Injectable } from '@angular/core';
import { TipoAcessoEnum } from '@decisaosistemas/typescript-service-plataforma';

@Injectable()
export class ControlaAplicacaoAtalho {

  public mostrar = false;
  public idAplicacao: string = null;
  public aplicacao;

  constructor() { }

  public reset(): void {
    this.aplicacao = null;
    this.idAplicacao = null;
    this.mostrar = null;
  }
  public isPossuiConvite() {
    if (!!this.aplicacao === false) {
      return false;
    }
    if (this.getQuantidadeConvitesDisponivel() > 0) {
      return true;
    }
    return false;
  }

  public getQuantidadeUsuariosConvidados(): number {
    if (this.aplicacao === null || this.aplicacao.acesso === null) {
      return 0;
    }
    return this.aplicacao.acessos.filter(pAcesso => pAcesso.isSuporte === false && pAcesso.tipoAcesso === TipoAcessoEnum.normal).length;
  }

  public getQuantidadeConvitesDisponivel(): number {
    if (this.aplicacao === null || this.aplicacao.acesso === null) {
      return 0;
    }
    const qtdConvites = this.aplicacao.contrato.usuariosContratados;
    const qtdUsuarios = this.aplicacao.acessos.filter(pAcesso => pAcesso.isSuporte === false && pAcesso.tipoAcesso === TipoAcessoEnum.normal).length;
    const qtdConvitesDisponiveis = qtdConvites - qtdUsuarios;
    return qtdConvitesDisponiveis;
  }
}
