import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, map, catchError } from 'rxjs/operators';

import { environment } from './../../../../environments/environment';
import { ApiUrl } from '../../../shared/constants/nome-url';
import { HeaderService } from '../../../shared/services/header.service';
import { HttpErroTratamentoUtil } from '../../classes/http-erro-tratamento-util';

@Injectable()
export class HttpIpAcessoService {

  constructor(
    private httpClient: HttpClient,
    private header: HeaderService,
    private httpErroTratamentoUtil: HttpErroTratamentoUtil
  ) { }


  buscaListaIpAcessoPorAplicacao(pIdAplicacao) {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.get(environment.API_ENDPOINT + ApiUrl.IP_ACESSO + pIdAplicacao, options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }

  buscaIpAcessoEspecifico(pIdAplicacao, pIdIpAcesso) {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.get(environment.API_ENDPOINT + ApiUrl.IP_ACESSO_ESPECIFICO + pIdAplicacao + '/' + pIdIpAcesso, options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }

  criaIpAcesso(pObjectIpAcesso) {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.post(environment.API_ENDPOINT + ApiUrl.IP_ACESSO, pObjectIpAcesso, options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }

  atualizaIpAcesso(pObjectIpAcesso) {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.patch(environment.API_ENDPOINT + ApiUrl.IP_ACESSO, pObjectIpAcesso, options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }

  removeIpAcesso(pIdAplicacao, pIdIpAcesso) {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.delete(environment.API_ENDPOINT + ApiUrl.IP_ACESSO + pIdAplicacao + '/' + pIdIpAcesso, options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }

}
