import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, map, catchError } from 'rxjs/operators';

import { HeaderService } from '../../shared/services/header.service';
import { HttpErroTratamentoUtil } from '../classes/http-erro-tratamento-util';
import { environment } from './../../../environments/environment';
import { ApiUrl } from '../../shared/constants/nome-url';

@Injectable()
export class AtualizaPerfilUsuarioService {

  constructor(
    private httpClient: HttpClient,
    private header: HeaderService,
    private httpErroTratamentoUtil: HttpErroTratamentoUtil,
  ) { }

  atualizaPerfilCompleto(pObjUsuario) {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.patch(environment.API_ENDPOINT + ApiUrl.GERENCIAR_USUARIO_DADOS_PERFIL, pObjUsuario, options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          // this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }
}
