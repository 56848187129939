import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, map, catchError } from 'rxjs/operators';

import { environment } from './../../../../environments/environment';
import { ApiUrl } from '../../../shared/constants/nome-url';
import { HeaderService } from '../../../shared/services/header.service';
import { HttpErroTratamentoUtil } from '../../classes/http-erro-tratamento-util';

@Injectable()
export class HttpGrupoAcessoService {
  constructor(
    private httpClient: HttpClient,
    private header: HeaderService,
    private httpErroTratamentoUtil: HttpErroTratamentoUtil
  ) { }

  criaGrupoAcesso(pObjetoGrupoAcesso) {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.post(environment.API_ENDPOINT + ApiUrl.GRUPO_ACESSO, pObjetoGrupoAcesso, options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }

  buscaListaGrupoAcesso(pIdAplicacao) {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.get(environment.API_ENDPOINT + ApiUrl.GRUPO_ACESSO + pIdAplicacao, options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }

  buscaGrupoAcesso(pIdAplicacao, pIdGrupoAcesso) {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.get(environment.API_ENDPOINT + ApiUrl.GRUPO_ACESSO + pIdAplicacao + '/' + pIdGrupoAcesso, options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }

  alteraGrupoAcessoMassa(pObjetoAtualizaGrupoAcesso) {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.patch(environment.API_ENDPOINT + ApiUrl.GRUPO_ACESSO + 'massa', pObjetoAtualizaGrupoAcesso, options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }

  editarGrupoAcesso(pObjGrupoAcesso) {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.patch(environment.API_ENDPOINT + ApiUrl.GRUPO_ACESSO, pObjGrupoAcesso, options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }

  excluiGrupoAcesso(pIdGrupoAcesso, pidAplicacao) {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.delete(environment.API_ENDPOINT + ApiUrl.GRUPO_ACESSO + pidAplicacao + '/' + pIdGrupoAcesso, options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }
}
