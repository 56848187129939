import { Injectable } from '@angular/core';
import { Settings } from './app.settings.model';

@Injectable()
export class AppSettings {
  public settings = new Settings(
    'Decisão Apps',
    'Angular Admin Template with Bootstrap 4',
    {
      menu: 'vertical', // horizontal , vertical
      menuType: 'mini', // default, compact, mini
      showMenu: true,
      navbarIsFixed: true,
      footerIsFixed: false,
      sidebarIsFixed: true,
      showSideChat: false,
      sideChatIsHoverable: true,
      skin: 'combined'  // light , dark, blue, green, combined, purple, orange, brown, grey, pink
    }
  )
}
