import { IEnvironments } from 'src/app/shared/interfaces/environments';

export const environment: IEnvironments = {
  env: 'HOMO',
  production: true,
  PLATAFORMA_URL: 'https://appshomologacao.decisaoinformatica.com.br',
  API_ENDPOINT: 'https://appshomologacao.decisaoinformatica.com.br/api',
  CENTRAL_ENDPOINT: 'https://apis.decisaoinformatica.com.br/central',
  API_ENDPOINT_MS_CEP: 'https://apis.decisaoinformatica.com.br/msCep',
  API_ENDPOINT_MS_DADOS_BANCARIOS: 'https://apishomologacao.decisaoinformatica.com.br/msDadoBancario',
  API_ENDPOINT_MS_B3: 'https://apishomologacao.decisaoinformatica.com.br/b3',
  PLATAFORMA_CLIENTE_URL: 'https://appclientehomologacao.decisaosistemas.com',
  API_ENDPOINT_MS_ZEEV: 'https://apishomologacao.decisaoinformatica.com.br/zeev',
  API_ENDPOINT_SOCKETIO_MS_ZEEV: 'https://apishomologacao.decisaoinformatica.com.br/zeev',
};
