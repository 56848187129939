import { Injectable } from '@angular/core';

@Injectable()
export class AppSharedService {
  public idAplicacao: String;
  public menu: any;
  public minhasAplicacoesTipoLista = 'grid'; // Valores : grid ou lista

  constructor() { }

}
