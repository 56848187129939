<div class="d-flex justify-content-center align-items-center w-100 fadeIn animated">
  <div class="col-md-8">
    <div class="row">
      <div class="col-md-7">
        <img class="w-100" src="assets/img/erro-404.svg" alt="">
      </div>
      <div class="col-md-5 d-flex align-items-center">
        <div class="d-block">
          <div>
            <p>Oops! Página não encontrada</p>
            <p>Lamentamos mas a página que você está procurando foi alterada ou excluída.</p>
          </div>
          <button class="btn btn-primary" (click)="direcionaParaHome()">
            Ir para a página inicial
          </button>
        </div>
      </div>
    </div>
  </div>
</div>