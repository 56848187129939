import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, map, catchError } from 'rxjs/operators';
import { environment } from './../../../../environments/environment';
import { ApiUrl } from '../../../shared/constants/nome-url';
import { HeaderService } from '../../../shared/services/header.service';
import { HttpErroTratamentoUtil } from '../../classes/http-erro-tratamento-util';
import { ICriarOperadorHttpResponse } from '../../interfaces/criarOperadorHttpResponse';

@Injectable()
export class HttpGerenteService {

  constructor(
    private httpClient: HttpClient,
    private header: HeaderService,
    private httpErroTratamentoUtil: HttpErroTratamentoUtil
  ) { }

  criaGerente(pObjectGerente): Promise<ICriarOperadorHttpResponse> {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.post(environment.API_ENDPOINT + ApiUrl.GERENTE, pObjectGerente, options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }

  buscaGerentePorId(pIdAplicacao: number, pIdGerente: number) {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.get(`${environment.API_ENDPOINT}${ApiUrl.GERENTE_ESPECIFICO}${pIdAplicacao}/${pIdGerente}`, options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }

  buscaGerentePorSolucao(pIdAplicacao: number) {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.get(`${environment.API_ENDPOINT}${ApiUrl.GERENTE}${pIdAplicacao}`, options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }

  atualizaGerente(pObjectGerenteAtualizar) {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.patch(`${environment.API_ENDPOINT}${ApiUrl.GERENTE}`, pObjectGerenteAtualizar, options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }

  removerVinculoGerenteUsuario(pIdAplicacao: string, pIdGerente: number) {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.delete(`${environment.API_ENDPOINT}${ApiUrl.GERENTE}acesso/${pIdAplicacao}/${pIdGerente}`, options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }
}
