<div class="app" [ngClass]="settings.theme.skin" [class.navbar-fixed]="settings.theme.navbarIsFixed"
    [class.sidebar-fixed]="settings.theme.sidebarIsFixed" [class.horizontal-menu]="settings.theme.menu == 'horizontal'"
    [class.compact]="settings.theme.menuType == 'compact'" [class.mini]="settings.theme.menuType == 'mini'"
    [class.menu-hide]="!settings.theme.showMenu">
    <div [ngClass]="{'hiddenScroll': colorWrapper, 'noneScroll': !colorWrapper}"
        [ngStyle]="{'background-color': !colorWrapper ? '#e9ecef' : '#0A5BBF'}" class="wrapper"
        style="position: relative;">

        <router-outlet></router-outlet>

    </div>
</div>

<app-loader></app-loader>