import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, map, catchError } from 'rxjs/operators';

import { HttpErroTratamentoUtil } from 'src/app/shared/classes/http-erro-tratamento-util';
import { environment } from 'src/environments/environment';
import { NMsZeevService } from 'src/app/shared/interfaces/servicos/msZeev.service';
import { Socket } from 'ngx-socket-io';

@Injectable()
export class MsZeevService {
  notifications = this.socket.fromEvent<any>('notificacoes');

  constructor(
    private httpClient: HttpClient,
    private httpErroTratamentoUtil: HttpErroTratamentoUtil,
    private socket: Socket
  ) {
  }

  // emit event
  fetchNotificacoes() {
    this.socket.emit('notificacoes');
  }

  // listen event
  onFetchNotificacoes() {
    return this.socket.fromEvent('notificacoes');
  }

  listarBibliotecas(): Promise<{ dados: NMsZeevService.IBiblioteca[] }> {
    return this.httpClient.get(environment.API_ENDPOINT_MS_ZEEV + '/biblioteca', {
      headers: {
        'Content-Type': 'application/json',
      },
    }).pipe(
      tap((response: any) => {
        return response;
      }),
      map(res => {
        return res;
      }),
      catchError(error => {
        console.log(error)
        this.httpErroTratamentoUtil.tratamento(error.error);
        return Promise.reject(error.error);
      })
    )
      .toPromise()
  }

  listarArquivoPorBiblioteca(pAppCode: string): Promise<{ dados: NMsZeevService.IBiblioteca }> {
    return this.httpClient.get(environment.API_ENDPOINT_MS_ZEEV + `/biblioteca/${pAppCode}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).pipe(
      tap((response: any) => {
        return response;
      }),
      map(res => {
        return res;
      }),
      catchError(error => {
        console.log(error)
        this.httpErroTratamentoUtil.tratamento(error.error);
        return Promise.reject(error.error);
      })
    )
      .toPromise()
  }

  criarBiblioteca(pBody: NMsZeevService.ICriarBiblioteca): Promise<{ dados: NMsZeevService.IBiblioteca }> {
    return this.httpClient.post(environment.API_ENDPOINT_MS_ZEEV + `/biblioteca`, pBody, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).pipe(
      tap((response: any) => {
        return response;
      }),
      map(res => {
        return res;
      }),
      catchError(error => {
        console.log(error)
        this.httpErroTratamentoUtil.tratamento(error.error);
        return Promise.reject(error.error);
      })
    )
      .toPromise()
  }

  realizarBackup(pBody: NMsZeevService.IRealizarBackup): Promise<any> {
    return this.httpClient.post(environment.API_ENDPOINT_MS_ZEEV + `/realizar-backup`, pBody, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).pipe(
      tap((response: any) => {
        return response;
      }),
      map(res => {
        return res;
      }),
      catchError(error => {
        this.httpErroTratamentoUtil.tratamento(error.error);
        return Promise.reject(error.error);
      })
    )
      .toPromise()
  }

  buscarArquivoParaDownload(pBody: NMsZeevService.IArquivoBody): Promise<{ dados: NMsZeevService.IArquivoDownload }> {
    return this.httpClient.post(environment.API_ENDPOINT_MS_ZEEV + `/buscar-arquivo`, pBody, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).pipe(
      tap((response: any) => {
        return response;
      }),
      map(res => {
        return res;
      }),
      catchError(error => {
        console.log(error)
        this.httpErroTratamentoUtil.tratamento(error.error);
        return Promise.reject(error.error);
      })
    )
      .toPromise()
  }

  listarUsuarioZeev(): Promise<{ dados: NMsZeevService.IUsuarioZeev[] }> {
    return this.httpClient.get(environment.API_ENDPOINT_MS_ZEEV + '/usuarios-zeev', {
      headers: {
        'Content-Type': 'application/json',
      },
    }).pipe(
      tap((response: any) => {
        return response;
      }),
      map(res => {
        return res;
      }),
      catchError(error => {
        console.log(error)
        this.httpErroTratamentoUtil.tratamento(error.error);
        return Promise.reject(error.error);
      })
    )
      .toPromise()
  }

  criarUsuarioZeev(pDadosUsuario: NMsZeevService.ICriarUsuarioZeev): Promise<{ dados: NMsZeevService.ICriarUsuarioZeev }> {
    return this.httpClient.post(environment.API_ENDPOINT_MS_ZEEV + '/criar-usuario',
      {
        usuarioZeev: pDadosUsuario.usuarioZeev,
        email: pDadosUsuario.email,
        chavePublica: pDadosUsuario.chavePublica
      }, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).pipe(
      tap((response: any) => {
        return response;
      }),
      map(res => {
        return res;
      }),
      catchError(error => {
        console.log(error)
        this.httpErroTratamentoUtil.tratamento(error.error);
        return Promise.reject(error.error);
      })
    )
      .toPromise()
  }

  deletarUsuarioZeev(pIdUsuario: string) {
    return this.httpClient.delete(`${environment.API_ENDPOINT_MS_ZEEV}/usuarios-zeev/${pIdUsuario}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }).pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(error => {
          console.log(error)
          this.httpErroTratamentoUtil.tratamento(error.error);
          return Promise.reject(error.error);
        })
      )
      .toPromise()
  }
}
