export class ApiUrl {
  public static LOGIN = '/login';

  public static LOGOUT = '/logout/';

  public static ALTERAR_DADOS_SEGURANCA = '/usuario/senha/';

  public static RECUPERAR_TOKEN = '/login/recuperarSenha/';

  public static TROCAR_SENHA = '/login/trocarSenha/';

  public static CADASTRAR_PESSOA = '/usuario/cadastro/';

  public static GERENCIAR_USUARIO = '/usuario/administrativo';

  public static GERENCIAR_USUARIO_BLOQUEAR = '/usuario/bloquear/';

  public static GERENCIAR_USUARIO_DESBLOQUEAR = '/usuario/desbloquear/';

  public static GERENCIAR_USUARIO_PERFIL = '/usuario/perfilUsuario';

  public static GERENCIAR_USUARIO_DADOS_PERFIL = '/usuario/perfil/';

  public static GERENCIAR_USUARIO_CONVITE = '/usuario/reenviarconvite/';

  public static GERENCIAR_APLICATIVO = '/gerenciarAplicacao/';

  public static GERENCIAR_APLICATIVO_LIBERAR = '/gerenciarAplicacao/liberarContrato';

  public static GERENCIAR_APLICATIVO_DIACESSO = '/gerenciarAplicacao/diacesso/';

  public static GERENCIAR_APLICATIVO_OMIE = '/gerenciarAplicacao/contratosOmie/';

  public static GERENCIAR_APLICATIVO_EXCLUIR = '/gerenciarAplicacao/excluir/';

  public static GERENCIAR_APLICATIVO_ATIVAR = '/gerenciarAplicacao/ativar/';

  public static GERENCIAR_APLICATIVO_EXPIRACAO = '/gerenciarAplicacao/expiracao/';

  public static GERENCIAR_CONVIDADO_RETORNO = '/app/';

  public static GERENCIAR_CONVIDADO_CRIAR = '/acesso/criar/';

  public static GERENCIAR_CONVIDADO = '/acesso/';

  public static GERENCIAR_CONVIDADO_ESPECIFICO = '/acesso/especifico/';

  public static GERENCIAR_CONVIDADO_FORM = '/acesso/form/';

  public static GERENCIAR_CONVIDADO_BLOQUEAR = '/acesso/bloquear/';

  public static GERENCIAR_CONVIDADO_DESBLOQUEAR = '/acesso/desbloquear/';

  public static LOGAR_APLICACAO = '/app/logar';

  public static LOGAR_APLICACAO_PARCEIRO = '/app/logar';

  public static GERENCIAR_CONVIDADO_CONVITE = '/usuario/reenviarconvite/';

  public static MINHAS_APLICACOES_LISTAR = '/app/listar';

  public static APLICACOES_PARCEIRAS_LISTAR = '/acesso/cliente/listar-parceiros';

  public static MINHAS_APLICACOES = '/associar/';

  public static ASSOCIAR_APLICACAO = '/associar';

  public static GRUPO_ACESSO = '/grupo-acesso/';

  public static FUNCIONALIDADE = '/funcionalidades/';

  public static PERMISSAO = '/permissao/';

  public static GERENTE = '/operador/';

  public static GERENTE_ESC_CR = '/gerente';

  public static GERENTE_ESPECIFICO = '/operador/especifico/';

  public static SOLUCAO = '/solucao/';

  public static SOLUCAO_DUPLICADA = '/solucao/duplicada';

  public static IP_ACESSO = '/ipAcesso/';

  public static IP_ACESSO_ESPECIFICO = '/ipAcesso/especifico/';

  public static USUARIO = '/usuario/';
}
