import { Injectable } from '@angular/core';
import * as JSZip from 'jszip';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../components/loader/loader.service';

import { IFormularioPendente, IGerarContratoRetono, IGerarFormularioDto } from '../interfaces/CentralDadosService';
import { CentralDadosService } from '../services/http/centralDados.service';

@Injectable()
export class ContratoClienteHelper {
    constructor(
        private centralDadosService: CentralDadosService,
        private loaderService: LoaderService,
    ) { }


    public async gerarContratosPorIdContrato(pIdContrato: number): Promise<void> {
        try {
            this.loaderService.show();
            const contratosMesclados = await this.centralDadosService.gerarContratosRenovar(pIdContrato);
            const zip = new JSZip();

            contratosMesclados.dados.forEach((pArquivo: IGerarContratoRetono) => {
                zip.file(pArquivo.nomeArquivo, pArquivo.arquivoBase64, { base64: true });
            });

            zip.generateAsync({ type: 'base64' }).then((content) => {
                const link = document.createElement('a');
                link.style.display = 'none';
                document.body.appendChild(link);

                link.setAttribute('download', 'contratos-renovar-' + pIdContrato + '.zip');
                link.setAttribute('href', 'data:application/zip;base64,' + content);
                link.click();

                document.body.removeChild(link);
                this.loaderService.hide();
            });
        } catch (err) {
            this.loaderService.hide();
            return Promise.reject(err);
        }
    }

    public async gerarFormularioPublico(pDados: IGerarFormularioDto): Promise<IFormularioPendente> {
        try {
            this.loaderService.show();
            const formularioPendente = await this.centralDadosService.gerarFormularioPublico(pDados);
            this.loaderService.hide();
            return Promise.resolve(formularioPendente.dados);
        } catch (error) {
            this.loaderService.hide();
            return Promise.reject(error);
        }
    }

    public gerarLinkFormularioPublico(pIdFormularioPendente: string): string {
        return `${environment.PLATAFORMA_URL}/public/formularioContrato/${pIdFormularioPendente}`;
    }
}