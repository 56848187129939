import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';

import { ApiUrl } from '../../../shared/constants/nome-url';
import { HeaderService } from '../../../shared/services/header.service';
import { HttpErroTratamentoUtil } from '../../classes/http-erro-tratamento-util';
import { environment } from './../../../../environments/environment';
import { AplicacaoInterface } from './../../interfaces/dbModels.interface';
interface AlteraAssociacao {
  id_aplicacao: number,
  aplicacoesAssociaveis: Array<AplicacaoInterface>
}

@Injectable()
export class HttpAplicacaoService {
  constructor(
    private httpClient: HttpClient,
    private header: HeaderService,
    private httpErroTratamentoUtil: HttpErroTratamentoUtil
  ) { }

  logarAplicacao(pIdAplicacao, pIdAcesso) {
    const options = this.header.retornaHeaderHttpClient();
    const body = {
      idApp: pIdAplicacao,
      idAcesso: pIdAcesso,
    };

    return this.httpClient.post(environment.API_ENDPOINT + ApiUrl.LOGAR_APLICACAO, body, options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          window.open(
            res.dados.aplicacaoUrl + '/logar/' + res.dados.token + '/' + res.dados.tokenAplicacao + '/' + res.dados.codigoAplicacao,
            '_blank'
          );
          return res;
        }),
        catchError(error => {
          this.httpErroTratamentoUtil.tratamento(error.error);
          return Promise.reject(error.error);
        })
      )
      .toPromise()
  }

  logarAplicacaoParceiro(pIdAplicacao, pIdAcesso) {
    const options = this.header.retornaHeaderHttpClient();
    const body = {
      idApp: pIdAplicacao,
      idAcesso: pIdAcesso,
    };

    return this.httpClient.post(environment.API_ENDPOINT + ApiUrl.LOGAR_APLICACAO_PARCEIRO, body, options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          window.open(
            `${res.dados.aplicacaoUrl}/logar/${res.dados.token}/${res.dados.tokenAplicacao}`,
            '_blank'
          );
          return res;
        }),
        catchError(error => {
          this.httpErroTratamentoUtil.tratamento(error.error);
          return Promise.reject(error.error);
        })
      )
      .toPromise()
  }

  buscaAplicacaoPorId(pIdAplicacao) {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.get(environment.API_ENDPOINT + ApiUrl.GERENCIAR_CONVIDADO_RETORNO + pIdAplicacao, options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }

  listaMinhasAplicacoes() {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.get(environment.API_ENDPOINT + ApiUrl.MINHAS_APLICACOES_LISTAR, options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }

  listarAplicacoesParceiras() {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.post(environment.API_ENDPOINT + ApiUrl.APLICACOES_PARCEIRAS_LISTAR, { sistema: 'PLATAFORMACLIENTE'} , options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }
}
