import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class RouterStrategyOverride {

  constructor(
    private router: Router,
  ) { }

  public routeReuseStrategyFalse() {
    /**
     * Esse método sobrescreve a estrategia de rota permitindo que quando seja direcionado
     * para a mesma pagina no qual foi chamado a pagina seja recarregada.
     */
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }
}
