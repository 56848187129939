import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderService } from './header.service';
import { MensagemToastrService } from './mensagem-toastr.service';
import { ReEnviaConviteService } from './reenvia-convite.service';
import { AtualizaPerfilUsuarioService } from './atualiza-perfil-usuario.service';
import { ControlaMenuService } from './controlaMenu.service';
import { ControlaAplicacaoAtalho } from './controlaAplicacaoAtalho.service';
import { HttpAplicacaoService } from './http/aplicacao.service';
import { HttpGerenteService } from './http/gerente.service';
import { HttpIpAcessoService } from './http/ip-acesso.service';
import { HttpGrupoAcessoService } from './http/grupo-acesso.service';
import { HttpFuncionalidadeService } from './http/funcionalidade.service';
import { HttpAcessoService } from './http/acesso.service';
import { HttpLoginService } from './http/login.service';
import { HttpClientModule } from '@angular/common/http';
import { MsCepService } from './http/ms-cep.service';
import { CentralDadosService } from './http/centralDados.service';
import { ContratoClienteHelper } from '../helpers/contratoCliente.helper';
import { MsB3Service } from './http/msB3/msB3.service';
import { MsZeevService } from './http/gestao-arquivos/gestao-arquivos.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [],
  providers: [
    HeaderService,
    MensagemToastrService,
    ReEnviaConviteService,
    AtualizaPerfilUsuarioService,
    ControlaMenuService,
    ControlaAplicacaoAtalho,
    HttpAplicacaoService,
    HttpGerenteService,
    HttpIpAcessoService,
    HttpGrupoAcessoService,
    HttpFuncionalidadeService,
    HttpAcessoService,
    HttpLoginService,
    MsCepService,
    CentralDadosService,
    ContratoClienteHelper,
    MsB3Service,
    MsZeevService,
  ]
})
export class ServicesModule { }
