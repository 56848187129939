import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route } from '@angular/router';

import { MensagemToastrService } from '../services/mensagem-toastr.service';
import { FuncoesBasicasService } from '../classes/usuario-util';

@Injectable()
export class AuthGestaoArquivosGuard implements CanActivate {

  private arrayPermissao = [
    'mauricio@decisaosistemas.com.br',
    'almir@decisaosistemas.com.br',
    'belissa.tavares@decisaosistemas.com.br',
    'tuany@decisaosistemas.com.br'
  ]

  constructor(
    private router: Router,
    private funcoesBasicasService: FuncoesBasicasService,
    private toastr: MensagemToastrService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {

    return this.verificarAcesso();
  }

  private verificarAcesso() {
    if (this.funcoesBasicasService.haToken() === true) {
      const token = this.funcoesBasicasService.retornaDadosToken();

      if (!this.arrayPermissao.includes(token.email)) {
        this.toastr.apresentarMensagem('Oops, você não tem permissão de acesso a esta funcionalidade', '', 'error');
        this.router.navigate(['/pages/financeiro']);
      }

      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }

}
