import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MensagemControlErroComponent } from './mensagem-control-erro.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule
    ],
    exports: [MensagemControlErroComponent],
    declarations: [
      MensagemControlErroComponent,
  ],
    providers: [],
})
export class MensagemControlErroModule { }
