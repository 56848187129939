import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import md5 from 'md5';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class SegurancaUtil {

    constructor() { }

    getSecret = (email) => {

        const secretInit = email.split('@')[0]
        let secret = '';
        const hoje = new Date();
        if (secretInit.length > 3) {
            secret = secretInit.slice(0, 2);
            secret += secretInit.slice(secretInit.length - 2, secretInit.length);
        } else {
            secret = email.slice(0, 2);
            secret += email.slice(email.length - 2, email.length);
        }
        secret += `${hoje.getFullYear()}${hoje.getMonth()}${hoje.getDay()} `;
        return secret;
    };

    encryptAes = (text, secret) => {
        const ciphertext = CryptoJS.AES.encrypt(text, secret);
        return ciphertext.toString();
    };

    decryptAes = (text, secret) => {
        try {
            const bytes = CryptoJS.AES.decrypt(text, secret);
            const plaintext = bytes.toString(CryptoJS.enc.Utf8);
            return plaintext;
        } catch (err) {
            return null;
        }
    };

    encryptMD5 = (text) => {
        return md5(text);
    };
}
