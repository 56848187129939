import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { MensagemToastrService } from '../services/mensagem-toastr.service';

@Injectable()
export class HttpErroTratamentoUtil {
  constructor(
    private router: Router,
    private mensagemToastrService: MensagemToastrService,
  ) { }

  public tratamento(requisicao) {
    if (requisicao.status === 0 || requisicao.status === 500) {
      this.mensagemToastrService.apresentarMensagem('Teste', '', 'danger');
      this.removeToken();
      this.router.navigate(['/login']);
      return false;
    }

    const body = requisicao;
    switch (body.codigo) {
      case 1: // Usuário logado em outro dispositivo.
        this.removeToken();
        this.router.navigate(['/login']);
        break;
      case 2: // Token não encontrado invalido.
        this.removeToken();
        this.router.navigate(['/login']);
        break;
      case 3: // Token inválido
        this.removeToken();
        this.router.navigate(['/login']);
        break;
      case 4: // Usuário não possui esse APP
        this.router.navigate(['/pages/minhas-aplicacoes']);
        this.mensagemToastrService.apresentarMensagem('Sem acesso a aplicação', '', 'info');
        break;
      case 5: // Usuário não e administrador deste APP
        this.router.navigate(['/pages/minhas-aplicacoes']);
        this.mensagemToastrService.apresentarMensagem('Sem acesso a aplicação', '', 'info');
        break;
      case 6: // Usuário não e proprietario do aplicativo.
        this.router.navigate(['/pages/minhas-aplicacoes']);
        this.mensagemToastrService.apresentarMensagem('Sem acesso a aplicação', '', 'info');
        break;
      case 7: // Permisão negada!
        this.router.navigate(['/pages/minhas-aplicacoes']);
        this.mensagemToastrService.apresentarMensagem('Sem acesso a aplicação', '', 'info');
        break;
      case 8: // O link é inválido ou pode não estar disponível.
        this.removeToken();
        this.router.navigate(['/login']);
        break;
      case 9: // Token incompativel. Host não compativel.
        this.removeToken();
        this.router.navigate(['/login']);
        break;
      case 10: // Multiplos dispositivos detectado.
        this.removeToken();
        this.router.navigate(['/login']);
        break;
      case 12: // Usuário não compativel com o token.
        this.removeToken();
        this.router.navigate(['/login']);
        break;
      case 13: // Acesso negado a informação ou não encontrada.
        this.router.navigate(['/pages/minhas-aplicacoes']);
        this.mensagemToastrService.apresentarMensagem('Informação não encontrada', '', 'info');
        break;
    }
  }

  private removeToken() {
    localStorage.removeItem('token');
  }
}

