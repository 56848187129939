// https://gist.github.com/wgbn/e0d48ecf7194d5c5f8582272680c8659

import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class EventEmitterService {

    public static emitters: { [nomeEvento: string]: EventEmitter<any> } = {}

    static get(nomeEvento: string): EventEmitter<any> {
        if (!this.emitters[nomeEvento]) {
            this.emitters[nomeEvento] = new EventEmitter<any>();
        }

        return this.emitters[nomeEvento];
    }
}
