import { Injectable } from '@angular/core';

@Injectable()
export class AppListShortcutSharedService {
  public monstrarLista = false;
  public idUsuario: string;
  public listaAplicacoes: any[];

  constructor() { }

}
