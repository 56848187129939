import { Injectable } from '@angular/core';
import { ToastrService, GlobalConfig } from 'ngx-toastr';

@Injectable()
export class MensagemToastrService {
  // Variaveis do Toastr
  options: GlobalConfig;
  title = '';

  constructor(private toastrService: ToastrService) {
    this.options = this.toastrService.toastrConfig;
    this.options.closeButton = true;
    this.options.timeOut = 2000;
    this.options.extendedTimeOut = 100;
    this.options.autoDismiss = true;
    this.options.positionClass = 'toast-bottom-center';
  }

  apresentarMensagem(mensagem, titulo, tipo) {
    switch (tipo) {
      case 'success':
        this.toastrService.success(mensagem, titulo);
        break;
      case 'error':
        this.toastrService.error(mensagem, titulo);
        break;
      case 'warning':
        this.toastrService.warning(mensagem, titulo);
        break;
      case 'info':
        this.toastrService.info(mensagem, titulo);
        break;
    }
  }
}
