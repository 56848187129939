import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, map, catchError } from 'rxjs/operators';

import { environment } from './../../../../environments/environment';
import { NNectarService } from '../../../shared/interfaces/nectarNamespace';
import { IConsultaContratoVencer, IFormularioConsultaRetorno, IFormularioPendente, IGerarContratoRetono, IGerarFormularioDto } from '../../../shared/interfaces/CentralDadosService';

@Injectable()
export class CentralDadosService {
    constructor(
        private httpClient: HttpClient,
    ) { }

    isOportunidadeAguardandoDados(pIdOportunidade: number): Promise<any> {
        return this.httpClient.get(environment.CENTRAL_ENDPOINT + '/contrato/oportunidade/aguardandoDados/' + pIdOportunidade, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).pipe(
            tap((response: any) => {
                return response;
            }),
            map(res => {
                return res;
            }),
            catchError(error => {
                return Promise.reject(error);
            })
        )
            .toPromise()
    }

    atualizarContatosOportunidade(pEmpresa: any): Promise<any> {
        return this.httpClient.put(environment.CENTRAL_ENDPOINT + '/nectar/contatos/contrato', JSON.stringify(pEmpresa), {
            headers: {
                'Content-Type': 'application/json',
            },
        }).pipe(
            tap((response: any) => {
                return response;
            }),
            map(res => {
                return res;
            }),
            catchError(error => {
                return Promise.reject(error);
            })
        )
            .toPromise()
    }

    atualizarContatosContratos(pEmpresa: any): Promise<any> {
        return this.httpClient.put(environment.CENTRAL_ENDPOINT + '/formulario/contrato/omieContrato', JSON.stringify(pEmpresa), {
            headers: {
                'Content-Type': 'application/json',
            },
        }).pipe(
            tap((response: any) => {
                return response;
            }),
            map(res => {
                return res;
            }),
            catchError(error => {
                return Promise.reject(error);
            })
        )
            .toPromise()
    }

    listarOportunidadesAguardandoDados(): Promise<{ dados: NNectarService.IOportunidade[] }> {
        return this.httpClient.get(environment.CENTRAL_ENDPOINT + '/nectar/oportunidade/aguardandoDados', {
            headers: {
                'Content-Type': 'application/json',
            },
        }).pipe(
            tap((response: any) => {
                return response;
            }),
            map(res => {
                return res;
            }),
            catchError(error => {
                return Promise.reject(error);
            })
        )
            .toPromise()
    }

    buscarContatoPorCnpjCpf(pCnpjCpf: string): Promise<{ dados: NNectarService.IContato | null }> {
        return this.httpClient.get(environment.CENTRAL_ENDPOINT + '/nectar/contatos/buscar/cnpjCpf/' + pCnpjCpf, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).pipe(
            tap((response: any) => {
                return response;
            }),
            map(res => {
                return res;
            }),
            catchError(error => {
                return Promise.reject(error);
            })
        )
            .toPromise()
    }

    buscarOportunidadeComContatos(pIdOportunidade: number): Promise<{ dados: IFormularioConsultaRetorno | null }> {
        return this.httpClient.get(environment.CENTRAL_ENDPOINT + '/nectar/oportunidade/buscarFormularioContrato/' + pIdOportunidade, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).pipe(
            tap((response: any) => {
                return response;
            }),
            map(res => {
                return res;
            }),
            catchError(error => {
                return Promise.reject(error);
            })
        )
            .toPromise()
    }

    buscarContratoComContatos(pIdContratoOmie: number): Promise<{ dados: IFormularioConsultaRetorno | null }> {
        return this.httpClient.get(environment.CENTRAL_ENDPOINT + '/contrato/contatosFormulario/' + pIdContratoOmie, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).pipe(
            tap((response: any) => {
                return response;
            }),
            map(res => {
                return res;
            }),
            catchError(error => {
                return Promise.reject(error);
            })
        )
            .toPromise()
    }

    buscarFormularioDadosPendente(pIdFormularioPendente: string): Promise<{ dados: IFormularioConsultaRetorno | null }> {
        return this.httpClient.get(environment.CENTRAL_ENDPOINT + '/formulario/buscarDadosFormularioPendente/' + pIdFormularioPendente, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).pipe(
            tap((response: any) => {
                return response;
            }),
            map(res => {
                return res;
            }),
            catchError(error => {
                return Promise.reject(error);
            })
        )
            .toPromise()
    }

    gerarContratosOportunidade(pIdOportunidade: number): Promise<{ dados: IGerarContratoRetono[] }> {
        return this.httpClient.get(environment.CENTRAL_ENDPOINT + '/contrato/gerarContratos/oportunidade/' + pIdOportunidade, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).pipe(
            tap((response: any) => {
                return response;
            }),
            map(res => {
                return res;
            }),
            catchError(error => {
                return Promise.reject(error);
            })
        )
            .toPromise()
    }
    gerarContratosRenovar(pIdContratoOmie: number): Promise<{ dados: IGerarContratoRetono[] }> {
        return this.httpClient.get(environment.CENTRAL_ENDPOINT + '/contrato/gerarContratos/renovar/' + pIdContratoOmie, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).pipe(
            tap((response: any) => {
                return response;
            }),
            map(res => {
                return res;
            }),
            catchError(error => {
                return Promise.reject(error);
            })
        )
            .toPromise()
    }
    gerarFormularioPublico(pValores: IGerarFormularioDto): Promise<{ dados: IFormularioPendente }> {
        return this.httpClient.post(environment.CENTRAL_ENDPOINT + '/contrato/gerarContrato', pValores, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).pipe(
            tap((response: any) => {
                return response;
            }),
            map(res => {
                return res;
            }),
            catchError(error => {
                return Promise.reject(error);
            })
        )
            .toPromise()
    }

    listarContratosVencer(): Promise<{ dados: IConsultaContratoVencer[] }> {
        return this.httpClient.get(environment.CENTRAL_ENDPOINT + '/contrato/avencer', {
            headers: {
                'Content-Type': 'application/json',
            },
        }).pipe(
            tap((response: any) => {
                return response;
            }),
            map(res => {
                return res;
            }),
            catchError(error => {
                return Promise.reject(error);
            })
        )
            .toPromise()
    }

    buscarContratoPorNumero(pNumeroContrato: string): Promise<{ dados: IConsultaContratoVencer | null }> {
        return this.httpClient.post(environment.CENTRAL_ENDPOINT + '/contrato/buscarPorNumero', { numeroContrato: pNumeroContrato }, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).pipe(
            tap((response: any) => {
                return response;
            }),
            map(res => {
                return res;
            }),
            catchError(error => {
                return Promise.reject(error.error);
            })
        ).toPromise()
    }
}
