import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

interface Erros {
  senhaNovaNotLetra?: boolean,
  validaMinuscula?: boolean,
  validaMaiuscula?: boolean,
  senhaNovaNotNumero?: boolean,
  senhaConfirmacaoNotMatch?: boolean,
  senhaConfirmacaoNotMatchCheck?: boolean,
}

@Injectable()
export class SenhaValidacoes {

  constructor() { }

  verificaSeSenhasNoPadrao(senhaAtualKey: string, senhaNovaKey: string,
    senhaConfirmacaoKey: string, senhaValidacoes: SenhaValidacoes) {

    return (group: FormGroup) => {
      const senhaAtual = group.controls[senhaAtualKey];
      const senhaNova = group.controls[senhaNovaKey];
      const senhaConfirmacao = group.controls[senhaConfirmacaoKey];
      const erros: Erros = {};

      // Verifica se um texto contem ao menos uma letra
      if (!senhaNova.value && !senhaConfirmacao.value) {
        return null;
      }

      if (senhaValidacoes.isTextoContemLetraMaiusculaMinuscula(senhaNova.value) === false) {
        erros.senhaNovaNotLetra = true;
      }

      // if (/[a-z]/gm.test(senhaNova.value)) {
      //   erros.validaMinuscula = true;
      // }
      // if (/[A-Z]/gm.test(senhaNova.value)) {
      //   erros.validaMaiuscula = true;
      // }

      // Verifica se um texto contem ao menos um número.
      if (senhaValidacoes.isTextoContemNumero(senhaNova.value) === false) {
        erros.senhaNovaNotNumero = true;
      }

      if ((senhaNova.value !== senhaConfirmacao.value)) {
        erros.senhaConfirmacaoNotMatchCheck = true;
      }
      if ((senhaNova.value !== senhaConfirmacao.value) && senhaConfirmacao.dirty && senhaConfirmacao.value !== '') {
        erros.senhaConfirmacaoNotMatch = true;
      }

      // // Verifica se senha atual é igual a nova
      // if ((senhaAtual.value === senhaNova.value) && senhaAtual.dirty && senhaNova.dirty && senhaNova.value !== '') {
      //     return { senhaNovaNotMatch: true };
      // }

      // Verifica se senha nova e diferente da confirmação
      return erros;
    }
  }

  isTextoContemLetraMaiusculaMinuscula(str) {
    return str.search(/[A-Z, a-z]/) >= 0;
  }

  isTextoContemNumero(str) {
    return str.search(/\d/) >= 0;
  }

}
