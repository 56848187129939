import { Injectable } from '@angular/core';
import * as jwt from 'jsonwebtoken';

@Injectable()
export class FuncoesBasicasService {

  constructor() { }

  retornaPerfilUsuario() {
    const token = localStorage.getItem('token');
    const decoded = jwt.decode(token);
    return decoded.perfil_usuario
  }

  retornaIdUsuario() {
    const token = localStorage.getItem('token');
    const decoded = jwt.decode(token);
    return decoded.id_usuario
  }

  retornaDadosToken() {
    const token = localStorage.getItem('token');
    const decoded = jwt.decode(token);
    return decoded
  }

  haToken() {
    const token = localStorage.getItem('token');
    return !!token;
  }

  permissaoGestaoArquivos() {
    return [
      'mauricio@decisaosistemas.com.br',
      'tuany@decisaosistemas.com.br'
    ]
  }
}
