import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, map, catchError } from 'rxjs/operators';

import { environment } from './../../../../environments/environment';
import { ApiUrl } from '../../../shared/constants/nome-url';
import { HeaderService } from '../../../shared/services/header.service';
import { HttpErroTratamentoUtil } from '../../classes/http-erro-tratamento-util';

@Injectable()
export class HttpAcessoService {

  constructor(
    private httpClient: HttpClient,
    private header: HeaderService,
    private httpErroTratamentoUtil: HttpErroTratamentoUtil
  ) { }

  buscaValoresConvidadoForm(pIdAplicacao, pIdAcesso) {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.get(environment.API_ENDPOINT + ApiUrl.GERENCIAR_CONVIDADO_FORM + pIdAplicacao + '/' + pIdAcesso, options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }

  criarAcesso(pBodyAcesso) {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.post(environment.API_ENDPOINT + ApiUrl.GERENCIAR_CONVIDADO_CRIAR, pBodyAcesso, options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }

  editarAcesso(pBodyAcesso) {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.patch(environment.API_ENDPOINT + ApiUrl.GERENCIAR_CONVIDADO, pBodyAcesso, options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }

  buscaListaConvidados(pIdAplicacao: number): Promise<any> {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.get(environment.API_ENDPOINT + ApiUrl.GERENCIAR_CONVIDADO + pIdAplicacao, options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }

  buscaConvidadoEspecifico(pIdAplicacao: string, pIdAcesso: string): Promise<any> {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.get(environment.API_ENDPOINT + ApiUrl.GERENCIAR_CONVIDADO_ESPECIFICO + pIdAplicacao + '/' + pIdAcesso, options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }

  bloqueaAcesso(pIdAplicacao: string, pidAcesso: string): Promise<any> {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.patch(
      environment.API_ENDPOINT + ApiUrl.GERENCIAR_CONVIDADO_BLOQUEAR + pIdAplicacao + '/' + pidAcesso, {}, options
    )
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }

  desBloqueaAcesso(pIdAplicacao: string, pidAcesso: string): Promise<any> {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.patch(
      environment.API_ENDPOINT + ApiUrl.GERENCIAR_CONVIDADO_DESBLOQUEAR + pIdAplicacao + '/' + pidAcesso, {}, options
    )
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }

  removeAcesso(pIdAplicacao: string, pidAcesso: string): Promise<any> {
    const options = this.header.retornaHeaderHttpClient();
    return this.httpClient.delete(environment.API_ENDPOINT + ApiUrl.GERENCIAR_CONVIDADO + pIdAplicacao + '/' + pidAcesso, options)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        map(res => {
          return res;
        }),
        catchError(err => {
          this.httpErroTratamentoUtil.tratamento(err.error);
          return Promise.reject(err.error);
        })
      )
      .toPromise()
  }
}
