import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { NMsCepService } from '../../interfaces/msCepService';


@Injectable({
    providedIn: 'root'
})
export class MsCepService {
    public tamanho: number;
    constructor(private http: HttpClient) { }

    public consultaCEP(pCep: string): Promise<NMsCepService.IConsultaCep> {
        /* Nova variável "cep" somente com dígitos. */
        const cepLimpo = pCep.replace(/\D/g, '');

        /* Verifica se campo cep possui valor informado. */
        /* Valida o formato do CEP. */
        return this.http.get(environment.API_ENDPOINT_MS_CEP + '/cep/' + cepLimpo)
            .pipe(
                map((response: any) => {
                    return response;
                }),
                catchError(error => {
                    throw (error);
                }),
            )
            .toPromise();
    }
}
