import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, map, catchError } from 'rxjs/operators';

import { HttpErroTratamentoUtil } from 'src/app/shared/classes/http-erro-tratamento-util';
import { environment } from 'src/environments/environment';
import { NMsB3Service } from 'src/app/shared/interfaces/servicos/msB3.services';
@Injectable()
export class MsB3Service {

    constructor(
        private httpClient: HttpClient,
        private httpErroTratamentoUtil: HttpErroTratamentoUtil,
    ) { }

    listarFaturamentosDisponiveis(): Promise<NMsB3Service.IListaFaturamentosDisponiveisRetorno> {
        return this.httpClient.get(environment.API_ENDPOINT_MS_B3 + '/faturamento/lista-disponiveis', {
            headers: {
                'Content-Type': 'application/json',
            },
        }).pipe(
            tap((response: any) => {
                return response;
            }),
            map(res => {
                return res;
            }),
            catchError(error => {
                console.log(error)
                this.httpErroTratamentoUtil.tratamento(error.error);
                return Promise.reject(error.error);
            })
        )
            .toPromise()
    }

    listarFaturamentoMesAno(pMes: number, pAno: number): Promise<NMsB3Service.IListaFaturamentoMesAnoRetono> {
        return this.httpClient.post(environment.API_ENDPOINT_MS_B3 + '/faturamento/faturamento-mes-ano', {
            ano: pAno,
            mes: pMes,
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).pipe(
            tap((response: any) => {
                return response;
            }),
            map(res => {
                return res;
            }),
            catchError(error => {
                console.log(error)
                this.httpErroTratamentoUtil.tratamento(error.error);
                return Promise.reject(error.error);
            })
        )
            .toPromise()
    }

    adicionarParceiroAnfac(pIdTitular: string): Promise<NMsB3Service.IAdicionarParceiroAnfactRetorno> {
        return this.httpClient.patch(environment.API_ENDPOINT_MS_B3 + '/titular/setarParceiroAnfac', { idTitular: pIdTitular }, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).pipe(
            tap((response: any) => {
                return response;
            }),
            map(res => {
                return res;
            }),
            catchError(error => {
                console.log(error)
                this.httpErroTratamentoUtil.tratamento(error.error);
                return Promise.reject(error.error);
            })
        )
            .toPromise()
    }

    removerParceiroAnfac(pIdTitular: string): Promise<NMsB3Service.IRemoverParceiroAnfactRetorno> {
        return this.httpClient.patch(environment.API_ENDPOINT_MS_B3 + '/titular/removerParceiroAnfac', { idTitular: pIdTitular }, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).pipe(
            tap((response: any) => {
                return response;
            }),
            map(res => {
                return res;
            }),
            catchError(error => {
                console.log(error)
                this.httpErroTratamentoUtil.tratamento(error.error);
                return Promise.reject(error.error);
            })
        )
            .toPromise()
    }

    atualizarRecalcularFaturamento(pIdFaturamento: string): Promise<NMsB3Service.IAtualizarRecalcularFaturamentoRetorno> {
        return this.httpClient.patch(environment.API_ENDPOINT_MS_B3 + '/faturamento/atualizar-recalcular-faturamento', { idFaturamento: pIdFaturamento }, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).pipe(
            tap((response: any) => {
                return response;
            }),
            map(res => {
                return res;
            }),
            catchError(error => {
                console.log(error)
                this.httpErroTratamentoUtil.tratamento(error.error);
                return Promise.reject(error.error);
            })
        )
            .toPromise()
    }

    buscarFaturamentoDetalhadoCsv(pIdFaturamento: string): Promise<NMsB3Service.IBuscarFaturamentoDetalhadoCsvRetorno> {
        return this.httpClient.post(environment.API_ENDPOINT_MS_B3 + '/faturamento/faturamento-detalhado-titular-csv', { idFaturamento: pIdFaturamento }, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).pipe(
            tap((response: any) => {
                return response;
            }),
            map(res => {
                return res;
            }),
            catchError(error => {
                console.log(error)
                this.httpErroTratamentoUtil.tratamento(error.error);
                return Promise.reject(error.error);
            })
        )
            .toPromise()
    }

    buscarFaturamentoCsv(pAno: number, pMes: number): Promise<NMsB3Service.IBuscarFaturamentoDetalhadoCsvRetorno> {
        return this.httpClient.post(environment.API_ENDPOINT_MS_B3 + '/faturamento/faturamento-detalhado-csv', { mes: pMes, ano: pAno }, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).pipe(
            tap((response: any) => {
                return response;
            }),
            map(res => {
                return res;
            }),
            catchError(error => {
                console.log(error)
                this.httpErroTratamentoUtil.tratamento(error.error);
                return Promise.reject(error.error);
            })
        )
            .toPromise()
    }
}
