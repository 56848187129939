import { Component, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import * as jwt from 'jsonwebtoken';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotFoundComponent implements AfterViewInit {

  router: Router;

  constructor(router: Router) {
    this.router = router;
  }

  searchResult(): void {
    this.router.navigate(['pages/search']);
  }

  ngAfterViewInit() {
    document.getElementById('preloader').classList.add('hide');
  }

  public direcionaParaHome() {
    const token = localStorage.getItem('token');
    if (token === null) {
      this.router.navigate(['/login']);
      return;
    }
    if (jwt.decode(token).perfil_usuario === 99) {
      this.router.navigate(['/pages/minhas-aplicacoes']);
    } else {
      this.router.navigate(['/pages/gerencia-aplicacao']);
    }
  }
}
