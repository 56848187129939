import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipeModule } from './pipes/pipe.module';
import { LoaderComponent } from './components/loader/loader.component';
import { EnderecoComponent } from './components/endereco/endereco.component';
import { TextMaskModule } from 'angular2-text-mask';
import { MensagemControlErroModule } from './components/padrao/mensagem-control-erro/mensagem-control-erro.module';
import { InfoValueComponent } from './components/info-value/info-value.component';

@NgModule({
  exports: [
    CommonModule,
    LoaderComponent,
    EnderecoComponent,
    InfoValueComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    PipeModule,
    TextMaskModule,
    MensagemControlErroModule,
  ],
  declarations: [LoaderComponent, EnderecoComponent, InfoValueComponent],
  providers: [],
})
export class SharedModule { }
