import { Component, ViewEncapsulation, OnDestroy } from '@angular/core';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
import { Router, ResolveStart, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

import { ControlaMenuService } from './shared/services/controlaMenu.service';
import { ControlaAplicacaoAtalho } from './shared/services/controlaAplicacaoAtalho.service';
import { HttpAplicacaoService } from './shared/services/http/aplicacao.service';

import { filter, map } from 'rxjs/operators';
import { AppSharedService } from './app.shared.service';
import { EventEmitterService } from './shared/services/event-emitter.service';
import { AppListShortcutSharedService } from './shared/theme/app-list-shortcut/app-list-shotcut-shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnDestroy {
  public settings: Settings;
  public colorWrapper: boolean = false;

  constructor(
    private appListShortcutSharedService: AppListShortcutSharedService,
    public appSettings: AppSettings,
    public appSharedService: AppSharedService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private controlaMenuService: ControlaMenuService,
    private controlaAplicacaoAtalho: ControlaAplicacaoAtalho,
    private httpAplicacaoService: HttpAplicacaoService
  ) {
    this.settings = this.appSettings.settings;
    this.monitoraMudancaRota();
    this.monitoraMudancaAplicacao();
  }

  ngOnDestroy() {
    EventEmitterService.get('atualizaAplicacaoShortcut').unsubscribe();
  }

  private async monitoraMudancaAplicacao() {
    EventEmitterService.get('atualizaAplicacaoShortcut').subscribe(async () => {
      this.controlaAplicacaoAtalho.aplicacao = await this.carregaAplicacaoShortcut(this.controlaAplicacaoAtalho.idAplicacao);
    });
  }

  private monitoraMudancaRota() {
    /**
     * Monitora a mudança de rotas para pegar as variaveis de rota.
     */
    this.router.events.pipe(
      filter(event => event instanceof ResolveStart),
      map(pEvent => {
        let rotaAtual = pEvent['state'].root;
        let ultimaRota = false;
        while (ultimaRota === false) {
          if (!!rotaAtual.firstChild === false) {
            ultimaRota = true;
          } else {
            rotaAtual = rotaAtual.firstChild;
          }
        }

        return rotaAtual;
      }),
    ).subscribe(async (pActiveRoute) => {
      this.controlaMenuService.showMenu = !!pActiveRoute.data.showMenu;
      this.appListShortcutSharedService.monstrarLista = !!pActiveRoute.data.appShortcutList;

      let idAplicacao = null;

      /** Controlar a cor de fundo do wrapper (cor de tela da aplicação "Login é tom de azul e a aplicação em tom de cinza") */
      if (pActiveRoute.data.title && (pActiveRoute.data.title === 'Login' ||
        pActiveRoute.data.title === 'RecuperarSenha' ||
        pActiveRoute.data.title === 'RedefinirSenha' ||
        pActiveRoute.data.title === 'CriarConta'
      )
      ) {
        this.colorWrapper = true;
      } else {
        this.colorWrapper = false;
      }

      if (!!pActiveRoute.data.appShortcut === true) {

        idAplicacao = pActiveRoute.paramMap.get('idApp');

        if (idAplicacao === null) {
          let parent: ActivatedRouteSnapshot = pActiveRoute;
          while (idAplicacao === null) {
            if (!!parent.paramMap.get('idApp') === false) {
              parent = parent.parent;
            } else {
              idAplicacao = parent.paramMap.get('idApp');
            }
          }
        }
        if (this.controlaAplicacaoAtalho.idAplicacao !== String(idAplicacao)) {
          this.appSharedService.idAplicacao = String(idAplicacao);
          this.controlaAplicacaoAtalho.idAplicacao = String(idAplicacao);
          this.controlaAplicacaoAtalho.aplicacao = await this.carregaAplicacaoShortcut(idAplicacao);
        }
      }
      this.appListShortcutSharedService.monstrarLista = !!pActiveRoute.data.appShortcutList;
      this.controlaAplicacaoAtalho.mostrar = !!pActiveRoute.data.appShortcut;
    });
  }

  private async carregaAplicacaoShortcut(pIdAplicacao) {
    try {
      const aplicacao = await this.httpAplicacaoService.buscaAplicacaoPorId(pIdAplicacao);
      return Promise.resolve(aplicacao.dados);
    } catch (err) {
      console.log(err);
    }
  }
};
