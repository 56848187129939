import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BooleanPipe } from './boolean.pipe';
import { CepPipe } from './cep.pipe';

import { CnpjCpfPipe } from './cnpj-cpf.pipe';
import { DataMomentPipe } from './data-moment.pipe';
import { SearchPipe } from './search.pipe';
import { TelefonePipe } from './telefone.pipe';

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    CnpjCpfPipe,
    DataMomentPipe,
    TelefonePipe,
    CepPipe,
    SearchPipe,
    BooleanPipe
  ],
  declarations: [
    CnpjCpfPipe,
    DataMomentPipe,
    TelefonePipe,
    CepPipe,
    SearchPipe,
    BooleanPipe,
  ],
  providers: [],
})
export class PipeModule { }
