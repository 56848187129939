import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ControlaMenuService {
  public showMenu = true;
  public event = new Subject<boolean>();

  constructor() { }

  public showMenuEvent(): void {
    this.showMenu = true;
    this.event.next(true);
  }
  public hideMenuEvent(): void {
    this.showMenu = false;
    this.event.next(false);
  }
}
