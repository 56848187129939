import { AppSharedService } from './app.shared.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EventEmitterService } from './shared/services/event-emitter.service';
import { MensagemToastrService } from './shared/services/mensagem-toastr.service';
import { HeaderService } from './shared/services/header.service';
import { SegurancaUtil } from './shared/classes/seguranca-util';
import { BrowserModule } from '@angular/platform-browser';

import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './shared/shared.module';
import { ServicesModule } from './shared/services/services.module';
import { TextMaskModule } from 'angular2-text-mask';

import { routing } from './app.routing';
import { AppSettings } from './app.settings';

import { AppComponent } from './app.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { SenhaValidacoes } from './shared/classes/senha-validacoes';

import { LoginService } from './login/login.service';
import { ToastrModule } from 'ngx-toastr';
import { FuncoesBasicasService } from './shared/classes/usuario-util';
import { HttpErroTratamentoUtil } from './shared/classes/http-erro-tratamento-util';
import { RouterStrategyOverride } from './shared/classes/router-strategy-override';
import { AppListShortcutSharedService } from './shared/theme/app-list-shortcut/app-list-shotcut-shared.service';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { AuthGestaoArquivosGuard } from './shared/guards/authGestaoArquivos.guard';
import { AuthPermissaoUsuarioGuard } from './shared/guards/authPermissaoUsuario.guard';

const config: SocketIoConfig = { url: environment.API_ENDPOINT_MS_ZEEV, options: { transports: ['websocket'] } };


@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    routing,
    NgbModule,
    SharedModule,
    ServicesModule,
    ToastrModule.forRoot(),
    SocketIoModule.forRoot(config),
    TextMaskModule,
    BrowserAnimationsModule
  ],
  providers: [AppSettings,
    SenhaValidacoes,
    SegurancaUtil,
    RouterStrategyOverride,
    LoginService,
    HeaderService,
    MensagemToastrService,
    EventEmitterService,
    FuncoesBasicasService,
    AppListShortcutSharedService,
    HttpErroTratamentoUtil,
    AppSharedService,
    AuthGestaoArquivosGuard,
    AuthPermissaoUsuarioGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
